<template>
  <v-app>
    <v-app-bar app flat class="top-nav" v-if="$vuetify.breakpoint.width > 767">
      <div class="nav-img" >
        <v-img
          alt="The Modern Groom"
          class="shrink m-4"
          contain
          src="../src/assets/img/whitelogo.png"
          transition="scale-transition"
          width="220"
           @click="backToHome()"
        />
      </div>

      <button
        class="bg-white radius-0 book-appointment"
        @click="onBookAppointment()"
      >
        BOOK APPOINTMENT
      </button>
      <v-btn
        href="https://themoderngroom.com/"
        target="_self"
        text
        class="groom-btn home nav"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar app flat class="top-nav" v-else>
      <div class="nav-img"  @click="backToHome()">
        <v-img
          alt="The Modern Groom"
          class="shrink m-4"
          contain
          src="../src/assets/img/whitelogo.png"
          transition="scale-transition"
          :width="$vuetify.breakpoint.width > 350 ? 220 : 200"
        />
      </div>
      <v-btn
        class="btn-menu radius-0"
        @click="dialogMenu = true"
        :height="30"
        :width="42"
        color="white"
      >
        <v-icon size="20">mdi-menu</v-icon>
      </v-btn>
      <v-dialog content-class="dialog-menu" v-model="dialogMenu" fullscreen>
        <v-card>
          <v-card-title light color="white" class="card-title">
            <v-toolbar-title>
              <div class="nav-img">
                <v-img
                  alt="The Modern Groom"
                  class="shrink m-4"
                  contain
                  src="../src/assets/img/themoderngroom2.png"
                  transition="scale-transition"
                  :width="$vuetify.breakpoint.width > 350 ? 220 : 200"
                />
              </div>
            </v-toolbar-title>
            <v-btn
              icon
              width="70"
              height="70"
              color="rgba(34,57,77,1)"
              @click="dialogMenu = false"
            >
              <v-icon size="50">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="card-text">
            <v-list-item class="home uppercase">
              <a
                class="link-url"
                href="https://themoderngroom.com/"
                target="_self"
              >
                Home
              </a>
            </v-list-item>
            <v-list-item class="book-appointment-list">
              <button
                class="bg-blue white--text radius-0 book-appointment"
                @click="onBookAppointment()"
              >
                BOOK APPOINTMENT
              </button>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main class="main">
      <transition mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-dialog
      v-model="dialogBookAppointment"
      persistent
      max-width="600px"
      width="fit-content"
      scrollable
    >
      <v-card class="dialog-book-appoinment">
        <v-btn
          icon
          width="50"
          height="50"
          color="rgba(34,57,77,1)"
          @click="dialogBookAppointment = false"
          class="btn-close"
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
        <BookAppointment></BookAppointment>
      </v-card>
    </v-dialog>
    <v-card>
      <v-footer class="pa-0">
        <v-card flat tile width="100%" class="text-center" color="#0e2942">
          <v-card-text class="white--text">
            <strong>© {{ new Date().getFullYear() }} The Modern Groom</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-card>
  </v-app>
</template>
<script>
import { ApiClient } from "@goldfishcode/modern-groom-api-sdk";
import BookAppointment from "./components/book-appointment/BookAppointment";
import EventBus from "./eventBus";

export default {
  name: "App",
  components: { BookAppointment },
  metaInfo() {
    return {
      title: "MG2",
      meta: [
        { name: "description", content: "Description tag" },
        { property: "og:title", content: "title" },
        { property: "og:site_name", content: "MG2" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },

  methods: {
    onBookAppointment: function () {
      this.dialogBookAppointment = true;
    },
    backToHome(){
      window.location="https://themoderngroom.com/"
    }
  },
  data: () => ({
    dialogMenu: false,
    dialogBookAppointment: false,
  }),
  beforeCreate: function () {
    const SessionStorage = {
      async set(key, value) {
        localStorage.setItem(key, value);
      },
      async get(key) {
        const store = localStorage.getItem(key);
        return store || "";
      },

      // eslint-disable-next-line class-methods-use-this
      async remove(key) {
        localStorage.removeItem(key);
      },
    };
    const config = {
      baseUrl: process.env.VUE_APP_BASE_URL,
      AUTH_SESSION_KEY: process.env.VUE_APP_AUTH_SESSION_KEY,
      session: SessionStorage,
    };
    ApiClient.setApiConfig(config);
  },
  created() {
    const el = this;
    EventBus.$on("openDialogAppointment", function () {
      el.dialogBookAppointment = true;
    });
  },
  beforeDestroy() {
    EventBus.$off("openDialogAppointment", function () {
      //do nothing
    });
  },
};
</script>

<style lang="scss" scoped>
.top-nav{
  .v-image {
    cursor: pointer;
  }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import SocialSharing from "vue-social-sharing";
import VueMeta from 'vue-meta';
import './assets/css/style.scss';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(SocialSharing)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

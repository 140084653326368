import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/suit',
    name: 'Suit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Suit.vue')
  },
  {
    path: '/suit-fitted',
    name: 'Suit Fitted',
    component: () => import('../components/SuitFitted.vue')
  },
  {
    path: '/view-suit',
    name: 'View Suit',
    component: () => import('../components/ViewSuit.vue')
  },
  {
    path: '/payment',
    name: 'View Suit',
    component: () => import('../components/SuitPayment.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
